import React, { useEffect, useState } from 'react'
import { Formik, Form, FormikHelpers as FormikActions } from 'formik';
import { useNavigate, useParams } from 'react-router-dom'
import { cloneDeep } from 'lodash'

import { ErrorBlockGeneric, FormGroupBootstrap, ConfirmedButton } from '../common-components'
import { post_new_responsabile, patch_responsabile, get_mie_squadre, get_user_secondari } from '../api'
import { handle_drf_form_error, urlencode_iso8601_date } from 'django-rest-react'
import { Squadra, UserResponsabileForm } from '../api/types'
import { displayScuola, displaySquadra } from '../utils'
import { useEvento, useScuola, useSessione } from '../reducers';

interface FormValues extends Omit<UserResponsabileForm, "password"> {
  confirm_password?: string,
  password?: string,
}

const pageMatch = useParams<{ pk: string }>


const NuovoResponsabile = (props: {}) => {
  const [squadre, setSquadre] = useState([] as Squadra[]);
  const [error, setError] = useState(null as string | null);
  const [original, setOriginal] = useState({
    first_name: "", last_name: "", email: "",
    username: "", password: "", confirm_password: "",
    responsabile: {
      squadre: [], scuole: [], newsletter: true,
    }
  } as FormValues)

  const eventi = useEvento(props);
  const sessioni = useSessione(props);
  const scuole = useScuola(props);
  const params = pageMatch();
  const navigate = useNavigate();

  const edit = !!params.pk;
  useEffect(() => {
    document.title = "Nuovo responsabile - GaS";
    let ieri = new Date();
    ieri.setDate(ieri.getDate() - 1);

    get_mie_squadre({
      sessione__evento__inizio__gte: urlencode_iso8601_date(ieri)
    }).then(setSquadre)
    if (edit) {
      get_user_secondari().then(users => {
        const found = users.find(usr => usr.id == parseInt(params.pk as string))
        if (!!found) {
          // Importante non riaggiungere qui i campi password e check_password.
          // Se il campo non esiste, al backend va bene, ma se aggiungi una password
          // finta, allora ti dà 400
          setOriginal(found)
        }
      })
    }
  }, [])

  const title = edit ? "Modifica" : "Nuovo"

  return (
    <div className="container">
      <h1 className="page-header">{title} responsabile di squadra</h1>
      <p>Su questa pagina puoi delegare la consegna delle risposte ai problemi ad un responsabile designato.
      </p>
      <Formik
        initialValues={original}
        onSubmit={(values_: FormValues, actions: FormikActions<FormValues>) => {
          const values = cloneDeep(values_);
          if (!edit) {
            if (values.password != values.confirm_password) {
              actions.setErrors({ confirm_password: "Le due password non corrispondono" })
              actions.setSubmitting(false);
              return;
            }
          }
          if (edit) {
            // @ts-ignore
            delete values.username
            delete values.email
          }
          const func = edit ?
            () => patch_responsabile(parseInt(params.pk as string), values) :
            () => post_new_responsabile(values as Parameters<typeof post_new_responsabile>[0])
          func()
            .then(() => navigate("/accounts/profile/"))
            .catch(err => handle_drf_form_error(err, actions, setError))
            .finally(() => actions.setSubmitting(false))
        }}
        enableReinitialize
      >
        {({ isSubmitting, handleReset }) => {
          return (
            <Form className="form needs-validation">
              <FormGroupBootstrap name="first_name" displayName="Nome" type="text" readOnly={edit} />
              <FormGroupBootstrap name="last_name" displayName="Cognome" type="text" readOnly={edit} />
              <FormGroupBootstrap name="email" type="email" readOnly={edit} />
              <FormGroupBootstrap name="username" help_text="Può essere uguale all'email. Utilizzerà questo campo per fare il login." type="text" readOnly={edit} />
              {!edit &&
                <>
                  <FormGroupBootstrap name="password" type="password" help_text="Una password sicura. Memorizzala, perché non potrai rileggerla." />
                  <FormGroupBootstrap name="confirm_password" type="password" displayName="Conferma password" />
                </>
              }
              <FormGroupBootstrap
                name="responsabile.squadre"
                displayName="Squadre"
                type="select-multiple" choices={squadre}
                displayChoice={(s: Squadra) => {
                  const scuola = scuole.find(scuola => scuola.id == s.scuola)
                  const scuolan = scuola ? scuola.nome : ""
                  return `${scuolan} - ${displaySquadra(s, sessioni, eventi)}`
                }}
                help_text={
                  "Seleziona tutte le squadre che vuoi affidare al responsabile " +
                  " secondario che stai creando. Puoi affidare la stessa squadra a più responsabili. " +
                  "Per selezionare più squadre contemporaneamente, tieni premuto Ctrl su Windows/Linux" +
                  " oppure il tasto mela su MacOs."
                }
              />
              <FormGroupBootstrap
                name="responsabile.scuole"
                displayName="Scuole"
                type="select-multiple" choices={scuole}
                displayChoice={displayScuola}
                help_text={"Puoi selezionare sia una scuola sola, sia un insieme di scuole." +
                  " In ogni caso il responsabile che crei potrà interagire solo con le squadre che gli vengono assegnate direttamente."}
              />
              <FormGroupBootstrap
                name="responsabile.newsletter"
                displayName="Newsletter" type="checkbox"
                help_text="Invieremo solo informazioni riguardanti i prossimi eventi."
              />
              <div className="d-flex flex-row justify-content-end bd-highlight mb-3">
                <ConfirmedButton type="warning" onSubmit={handleReset}>
                  Reset
                </ConfirmedButton>
                <button
                  disabled={isSubmitting}
                  className="mr-auto btn btn-primary"
                  type="submit"
                >
                  Invia
                </button>
                <ErrorBlockGeneric error={error} />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default NuovoResponsabile;
