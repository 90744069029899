import React, { Component, CSSProperties, useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import { plain_object } from 'django-rest-react';
import { sentry_log } from '../utils'


const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
}

interface IProps {
  load(): Promise<plain_object>
  children: React.ReactElement<any>
}

const Loadable = (props: IProps) => {
  const [error, setError] = useState<{ message: string } | null>(null);
  const [loading, setLoading] = useState(true);
  const [extra, setExtra] = useState<any | null>(null);

  const load = () => {
    props.load().then((dict: any) => {
      setLoading(false);
      setExtra(dict);
    }).catch((error: any) => {
      sentry_log(error);
      setError(error);
    })
  }

  useEffect(() => {
    load();
  }, [])


  if (error) {
    return (
      <div>
        <h4>Errore nel caricamento: {JSON.stringify(error.message)}</h4>
        <button onClick={load} className="btn btn-primary">Riprova</button>
      </div>
    );
  }
  if (loading) {
    return (
      <ClipLoader
        cssOverride={override}
        color={'#42f456'}
        loading={true}
      />
    );
  } else {
    let child = React.cloneElement(
      props.children,
      extra
    );
    return (
      <>
        {child}
      </>
    );
  }
}

export default Loadable
