import React, { Component } from 'react'

import { image_placeholder_url } from '../globals'



interface ownProps {
  website: string | null,
  image_src?: string | null,
  name: string,
  class_name: string,
  affiliation?: "SNS" | "SGSS",
  size: number
}

type IProps = ownProps;


const foto_rotonda_style = (size: number) => {
  return {
    margin: '0 auto',
    marginBottom: '0.75em',
    width: `${size}vw`,
    height: `${size}vw`,
    borderRadius: `${size / 2}vw`,
  }
}



class FotoContributor extends Component<IProps> {

  render() {
    let { website, image_src, name, class_name, affiliation, size } = this.props;
    if (!affiliation) {
      affiliation = "SNS"
    }
    let affiliation_banner;
    if (affiliation == "SNS") {
      affiliation_banner = <p className="text-muted">Scuola Normale Superiore</p>
    }
    const real_image_src = image_src ? image_src : image_placeholder_url;
    return (
      <div className="bannerino flex-grow-1">
        <a href={!!website ? website : undefined} className={class_name} style={foto_rotonda_style(size)}>
          <img
            src={real_image_src}
            className="img-thumbnail"
            alt={name}
            style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: `${size / 2}vw` }}
          />
        </a>
        <h4 className="text-row text-center">
          {website ?
            <a href={website}>
              {name}
            </a>
            :
            <>{name}</>
          }
        </h4>
        {affiliation_banner}
      </div>
    )
  }
}


export default FotoContributor
