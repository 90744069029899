import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { displayWhoami } from '../utils';
import { Responsabile, Tile } from '../components'
import { get_user_secondari, get_mie_squadre } from '../api'
import { useEvento, useLogin, useSessione } from '../reducers';

type User = Awaited<ReturnType<typeof get_user_secondari>>[0]
type Squadra = Awaited<ReturnType<typeof get_mie_squadre>>[0]


const Profile = (props: {}) => {
  const [userSecondari, setUserSecondari] = useState([] as User[]);
  const [mieSquadre, setMieSquadre] = useState([] as Squadra[]);
  const login = useLogin();

  const eventi = useEvento(props);
  const sessioni = useSessione(props);
  useEffect(() => {
    document.title = "Profilo personale - GaS"
    get_user_secondari().then(setUserSecondari)
    get_mie_squadre({}).then(setMieSquadre)
  }, [])


  if (!login.logged_in) {
    return (
      <div className="container">
        <h1>Non autenticato</h1>
        <p>Esegui il login per continuare</p>
      </div>
    );
  }
  const { user } = login;
  const resp = user.responsabile;
  if (!resp) {
    return (
      <div className="container">
        <h1 className="page-header">{displayWhoami(user)}</h1>
        <h3>I tuoi dati personali</h3>
        <p>Se vedi questo messaggio, il tuo utente non ha associato un responsabile ed è quindi un problema tecnico. Sei invitato a segnalarcelo</p>
      </div>
    )
  }
  return (
    <div className="container">
      <h1 className="page-header">{displayWhoami(user)}</h1>
      <h3>I tuoi dati personali</h3>
      <p>Questi dati non sono modificabili.</p>
      {resp.ruolo == "sp" && <p>Se vuoi modificarli, accedi a myOlifis e cambiali lì, provvederemo noi a sincronizzarli.</p>}
      <Responsabile user={user} squadre={mieSquadre} sessioni={sessioni} eventi={eventi} />
      <div>
        <h4>Eventi non ufficiali</h4>
        <p>Puoi creare da questa pagina una simulazione di gara. Sarai tu a scegliere data e ora dell'evento, i problemi e le squadre iscritte. Puoi usare questa funzionalità per esempio per organizzare un allenamento privato nella tua scuola o che coinvolge le scuole del circondario.</p>
        <Tile
          url={`/nuovo-evento/`}
          color="primary" text="Nuovo evento"
          icon="fa-plus"
        />
      </div>
      <h3>Gli altri responsabili delle tue scuole</h3>
      <p>I responsabili principali si registrano su myOlifis e creano lì le squadre. Questi responsabili possono poi delegare la consegna delle risposte delle squadre a dei responsabili secondari.</p>
      <p>Qui c'è la lista di altri responsabili principali e responsabili secondari delle scuole di cui sei responsabile.</p>
      {resp.ruolo == "sp" &&
        <Link to={`/nuovo-responsabile/`}><button className="btn btn-primary"><i className="fa fa-plus" /> Crea un nuovo responsabile</button></Link>
      }
      <div className="d-flex flex-wrap">
        {userSecondari
          .filter(usr => usr.id != user.id)
          .map(usr => <Responsabile key={usr.id} user={usr} squadre={mieSquadre} sessioni={sessioni} eventi={eventi} />)}
      </div>
    </div>
  );
}

export default Profile;
