import React, { useEffect, useState } from 'react';
import { Evento } from '../api/types';
import { useLocation, useParams } from "react-router";
import { isEmpty } from 'lodash'

import { EventoDettagli } from '../components';
import { useEvento } from '../reducers';
import { get_evento } from '../api';
import { get_evento_detail } from '../api/auto-apis';
import { dataToAnnoScolastico } from '../globals';


const pageMatch = useParams<{ pk: string, anno: string }>

const isUnofficial = (params: ReturnType<typeof pageMatch>, location: ReturnType<typeof useLocation>) => {
  return location.pathname == "/eventi/non-ufficiali/"
}

const isDetail = (params: ReturnType<typeof pageMatch>, location: ReturnType<typeof useLocation>) => {
  return location.pathname.match(/\/evento\/([0-9]+)\//)
}


const eventoToAnnoScolastico = (ev: Evento) => dataToAnnoScolastico(new Date(ev.inizio));


const getFilter = (params: ReturnType<typeof pageMatch>, location: ReturnType<typeof useLocation>) => {
  if (isDetail(params, location)) {
    return ((ev: Evento) => ev.id === parseInt(params.pk as string))
  }
  if (isUnofficial(params, location)) {
    return ((ev: Evento) => ev.tipologia === "pub" || ev.tipologia === "priv")
  }

  if (location.pathname == "/eventi/") {
    return ((ev: Evento) => ev.tipologia == "o")
  } else if (location.pathname.includes("/eventi/anno/")) {
    // /eventi/anno/2023/
    return ((ev: Evento) => eventoToAnnoScolastico(ev) == parseInt(params.anno as string) && ev.tipologia === "o")
  }
  return ((ev: Evento) => true)
}

const Eventi = (props: {}) => {
  const location = useLocation();
  const params = pageMatch();
  const eventi = useEvento(props);
  const [eventiUnoff, setEventiUnoff] = useState([] as Evento[]);

  useEffect(() => {
    document.title = "Eventi - GAS"
    if (isDetail(params, location)) {
      get_evento_detail(parseInt(params.pk as string)).then(ev => setEventiUnoff([ev]))
    }
    if (isUnofficial(params, location)) {
      get_evento({ tipologia__in: ["pub", "priv"] }).then(setEventiUnoff)
    }
  }, [location])

  const filter = getFilter(params, location);


  const today = new Date();
  const mapping = (ev: Evento) => <EventoDettagli key={ev.id} evento={ev} verbose />;
  const datefilter = (ev: Evento) => {
    let end = new Date(ev.fine);
    end.setDate(end.getDate() + 1);
    return end > today;
  }
  const sorting = (ev1: Evento, ev2: Evento) => {
    return (new Date(ev1.inizio)).getTime() - (new Date(ev2.inizio)).getTime();
  }
  // Necessaria deduplicare eventi nel caso si vada sulla pagina
  // per Evento corrente ed è ufficiale.
  const compareObjects = (e1: Evento, e2: Evento) => e1.id === e2.id;
  const evs = eventi.concat(eventiUnoff).filter(filter).sort(sorting).reduce((r: Evento[], o) => {
    if (!r.some(compareObjects.bind(null, o))) {
      r.push(o);
    }
    return r
  }, [])

  const programmati = evs.filter(datefilter)
  const passati = evs.filter(ev => !datefilter(ev)).sort((e1, e2) => -sorting(e1, e2)).map(mapping)
  const tuttopassato = !isEmpty(params) && location.pathname.includes("/eventi/anno") && (new Date()).getFullYear() > parseInt(params.anno as string)
  return (
    <div className="container py-2">
      {!tuttopassato &&
        <>
          <h1 className="page-header">Eventi programmati</h1>
          <div>
            {programmati.length == 0 && <p>Nessun evento programmato al momento.</p>}
            {programmati.map(mapping)}
          </div>
        </>
      }
      {passati.length > 0 &&
        <>
          <h1 className="page-header">Eventi passati</h1>
          <div>
            {passati}
          </div>
        </>
      }
    </div>
  );
}

export default Eventi;
