import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react'
import { MD5 } from 'crypto-js'

import { Studente, Country, City, Scuola, BaseUser, UserWhoami, Ruolo, Evento, Sessione, Squadra } from '../api/types';
import { DEBUG } from '../globals';
import { display_cached, WithId, Displayable } from 'django-rest-react';
import { persone_all, city_all, scuola_all, country_all } from '../reducers';
export { chunk, pad } from 'django-rest-react'



export function display_country(pk: number, displayfunc = (c: Country) => c.name): string {
  return display_cached(country_all, pk, displayfunc);
}

export const displayWhoami = (whom: UserWhoami) => {
  if (whom.first_name && whom.last_name) {
    return displayUser(whom);
  } else {
    return whom.username
  }
}

/**
 * Restituisce un nome uniforme per l'userextended
 */
export function displayPersona(pers: Studente): string {
  return pers.nome;
}

export function displayUser(user: BaseUser): string {
  return user.first_name + " " + user.last_name;
}

export function display_extended_user(pk: number, displayfunc = displayPersona): string {
  return display_cached(persone_all, pk, displayfunc);
}

export function displayCity(a: City): string {
  return a.name;
}

export function display_city(pk: number, region: number, displayfunc = displayCity): string {
  const filter = [{ name: "region", value: region as unknown as string }];
  return display_cached(city_all, pk, displayfunc, undefined, filter);
}


interface WithName extends WithId {
  nome: string
}
export function displayScuola(a: WithName): string {
  return a.nome;
}

export function display_scuola(
  pk: number, region: number, tipologia: string, displayfunc = displayScuola): string {
  const filter = [
    { name: "tipologia", value: tipologia },
    { name: "region", value: region as unknown as string }
  ];
  return display_cached(scuola_all, pk, displayfunc, undefined, filter);
}


/**
 * Prende una ministringa che è l'id di un displayable
 * e restituisce il nome guardabile
 */
export function displayDisplayable(obj: string, family: Displayable[]): string {
  const match = family.find((item) => item.id === obj);
  if (match) {
    return match.display;
  } else {
    console.warn("Non matchato il seguente: " + obj);
    return "";
  }
}


export const ws_base_url = () => {
  const proto = (window.location.protocol == "http:") ? "ws" : "wss"
  return `${proto}://${window.location.host}`
}


export function rgb_string_from_name(name: string): string {
  const hash = MD5(name).toString().substring(0, 6);
  const r = parseInt(hash.substring(0, 2), 16);
  const g = parseInt(hash.substring(2, 4), 16);
  const b = parseInt(hash.substring(4, 6), 16);
  const color = `rgb(${r}, ${g}, ${b})`;
  return color;
}


const hex_to_off = (num: string) => {
  const conversion = 50 / 6.37e6 / 3.1415 * 180;
  return (parseInt(num, 16) - 0x8fffffff) / 0xffffffff * conversion;
}

export function name_to_offsets(name: string) {
  const hash = MD5(name).toString();
  return [hex_to_off(hash.substring(0, 8)), hex_to_off(hash.substring(8, 16))]
}


export const find_italy = (clist: Country[]) => clist.find(item => (item.name == 'Italy' || item.name == 'Italia' || item.name == 'Repubblica Italiana'))


const hextodec = (val: string) => Number(`0x${val}`)

export const rgb_to_rgba = (rgb: string, alpha: number) => {
  const DEFAULT = "rgba(0, 0, 0, 0)"

  const res = rgb.match(/^rgb\(\s*(?<red>[0-9]+)\s*, \s*(?<green>[0-9]+)\s*, \s*(?<blue>[0-9]+)\s*\)$/);
  // Formato #aabbcc
  if (!res) {
    const res = rgb.match(/#(?<red>[0-9a-f]{2})(?<green>[0-9a-f]{2})(?<blue>[0-9a-f]{2})/)
    if (!res || !res.groups) return DEFAULT;
    const red = hextodec(res.groups.red)
    const green = hextodec(res.groups.green)
    const blue = hextodec(res.groups.blue)
    return `rgba(${red}, ${green}, ${blue}, ${alpha})`
  }
  const val = res.groups
  if (!val) return DEFAULT;
  return `rgba(${val.red}, ${val.green}, ${val.blue}, ${alpha})`
}

export const badgePermessi = (r: Ruolo) => {
  let color, titolo;
  switch (r) {
    case 'sp': color = "success"; titolo = "Responsabile principale"; break;
    case 'ss': color = "info"; titolo = "Responsabile squadra"; break;
    default: color = "danger"; titolo = "Errore"; break
  }

  return <span className={`badge badge-${color}`}> {titolo} </span>
}



export const range = (end: number) => Array.from(Array(end).keys())

export function sortedIndex<T>(array: T[], value: T, less: (a: T, b: T) => boolean) {
  var low = 0,
    high = array.length;

  while (low < high) {
    var mid = (low + high) >>> 1;
    if (less(array[mid], value)) low = mid + 1;
    else high = mid;
  }
  return low;
}



export const eventi_attivi = (ev: Evento[]) => {
  const adesso = new Date();
  return ev.filter(item => {
    const fine = new Date(item.fine);
    const inizio = new Date(item.inizio);
    inizio.setHours(inizio.getHours() - 24);
    return adesso < fine && adesso > inizio;
  })
}


export const displaySquadra = (squadra: Squadra, sessioni: Sessione[], eventi: Evento[]) => {
  const sessione = sessioni.find(ses => ses.id == squadra.sessione)
  const evento = eventi.find(ev => ev.id == sessione?.evento)
  return `${squadra.nome} - ${evento?.titolo} - ${sessione?.nome}`
}
