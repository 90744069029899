import React, { Component } from 'react';
import { connect } from "react-redux";

import Plot from 'react-plotly.js';
import { AppState } from '../reducers';
import { PunteggiSessione, Evento } from '../api/types';
import LoadingCircle from '../components/LoadingCircle'
import { rgb_string_from_name } from '../utils'


interface IProps {
  pk: number,
  punteggi: PunteggiSessione,
  nomi: { [squadra: string]: string; },
  evento: Evento,
}


const mapStateToProps = (state: AppState) => {
  return { punteggi: state.punteggi }
}


class IstogrammaPunteggi extends Component<IProps> {

  render() {
    if (this.props.evento) {
      const adesso = new Date();
      const inizio = new Date(this.props.evento.inizio);
      if (inizio > adesso) {
        return <p>{"L'evento non è ancora iniziato."}</p>;
      }
    }
    const pp = this.props.punteggi;
    if (Object.entries(pp).length === 0 && pp.constructor === Object) {
      return (
        <LoadingCircle />
      );
    }
    let p;
    try {
      p = pp[this.props.pk].states[0].classifica.sort((a, b) => a.totale - b.totale);
    }
    catch (err) {
      return (
        <LoadingCircle />
      );
    }

    const colori = p.map(item => rgb_string_from_name(item.nome));
    const nomi = p.map(item => this.props.nomi[item.nome]);
    const punteggi = p.map(item => {
      const { totale } = item;
      const montepremi_totale = item.problemi
        .map(iitem => iitem.punti_parziali[1])
        .reduce((acc, cur) => acc + cur)
      return [totale - montepremi_totale, montepremi_totale];
    })

    // Sistemiamo i negativi
    const punteggi_nn = punteggi.map(couple => {
      if (couple[0] >= 0) return couple
      if (couple[1] >= -couple[0]) return [0, couple[1] + couple[0]]
      return [couple[1] + couple[0], 0]
    })

    const pti_base = {
      // Notare il fatto che sono invertiti perché è orizzontale.
      x: punteggi_nn.map(item => item[0]),
      y: nomi,
      marker: {
        color: colori,
      },
      name: "Punteggio base",
      type: 'bar' as 'bar',
      orientation: 'h' as 'h',
    }
    const pti_montepremi = {
      x: punteggi_nn.map(item => item[1]),
      y: nomi,
      name: "Punteggio difficoltà",
      marker: {
        color: '#ffd700',
      },
      type: 'bar' as 'bar',
      orientation: 'h' as 'h',
    }
    const data = [pti_base, pti_montepremi];
    return (
      <Plot
        data={data}
        layout={{
          barmode: 'stack',
          width: 1600, height: 600, title: "Punteggio",
          margin: { l: 200 },
        }}
      />
    )
  }
}


const IstogrammaPunteggi_ = connect(mapStateToProps)(IstogrammaPunteggi);
export default IstogrammaPunteggi_;
