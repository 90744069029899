import React, { useEffect, useRef, useState } from 'react';
import { Field, Formik, Form, FormikHelpers as FormikActions } from 'formik';
import { useLocation, useParams } from 'react-router-dom'

import {
  MdMath, Spoiler,
  LoadingCircle, ConfirmedButton, DisplayOrario
} from '../components';
import { ErrorBlockGeneric, FormGroupBootstrap } from '../common-components'
import { DECIMAL_PLACES, canCancellareRisposte, isEventoAdmin, login_url } from '../globals'
import { useEvento, useLogin, useProblema, useSessione, useSquadra } from '../reducers';
import {
  post_risposta, post_jolly, get_risposta, get_jolly,
  refresh_problemi, delete_risposta
} from '../api';
import {
  Squadra, Risposta, Evento, Jolly,
  RispostaForm,
  Sessione,
} from '../api/types';
import { handle_drf_form_error } from 'django-rest-react';
import { nice_date, displaySquadra, nice_date_discorsiva } from '../utils';
import store from '../store'
import { add_toast_action } from '../actions'
import { get_evento, get_evento_detail, get_sessione, get_sessione_detail, get_squadra } from '../api/auto-apis';

const udm_swag_ = (udm: string) => (
  <div className="input-group-append">
    <div className="input-group-text">
      <MdMath source={udm} />
    </div>
  </div>
);

type OrdineKind = "t" | "p" | "s";
const pageMatch = useParams<{ pk: string }>

interface FormValues extends Omit<RispostaForm, "valore"> {
  isSecondButton: boolean,
  valore: number,
  auto_time: boolean,
}


const isEvento = (location: ReturnType<typeof useLocation>) => location.pathname.match(/^\/evento\/.+/)

const CustomInputForm = ({ field, form, ...props }: any) =>
  <input
    className="form-control"
    style={{ marginBottom: '20px', fontSize: '0.7em' }}
    {...field} {...props}
  />


const refreshProblemi = (evento: Evento) => {
  const inizio = new Date(evento.inizio)
  const adesso = new Date();
  if (inizio > adesso) {
    // Set timer che scatta 3 secondi dopo l'inizio
    return window.setTimeout(() => {
      refreshProblemi(evento);
    }, inizio.getTime() - adesso.getTime() + 3000);
  } else {
    refresh_problemi(evento.id);
  }
}


const filtraFactory = (location: ReturnType<typeof useLocation>, squadre: ReturnType<typeof useSquadra>, evento: ReturnType<typeof useEvento>[0], sessione: ReturnType<typeof useSessione>[0] | null) => (item: Risposta | Jolly) => {
  const sq = squadre.find(i => i.id == item.squadra);
  if (!sq) {
    return false;
  }
  if (isEvento(location)) {
    return evento?.sessioni?.includes(sq.sessione);
  } else {
    if (sessione) {
      return (sq.sessione == sessione.id);
    } else {
      return true;
    }
  }
}

const ConsegnaRisposta = (props: {}) => {

  const login = useLogin();
  const [evento, setEvento] = useState(null as Evento | null)
  const [sessioni, setSessioni] = useState([] as Sessione[]);
  const location = useLocation();
  const params = useParams();

  const [errore, setErrore] = useState(null as string | null);
  const [success, setSuccess] = useState(null as string | null);
  const [consegnate, setConsegnate] = useState([] as Risposta[]);
  const [spoilerRisposte, setSpoilerRisposte] = useState(false);
  const [spoilerJolly, setSpoilerJolly] = useState(false);
  const [jolly, setJolly] = useState([] as Jolly[]);
  const [ordineKind, setOrdineKind] = useState("t" as OrdineKind);
  const [ordineDir, setOrdineDir] = useState(-1);
  const [squadre, setSquadre] = useState([] as Squadra[]);

  const pk = parseInt(params.pk as string);
  const sessione = isEvento(location) ?
    null : sessioni.find(ses => ses.id === pk) || null;
  const problemi = useProblema(evento?.id);

  useEffect(() => {
    document.title = "Consegna Risposta - GAS"
    if (isEvento(location)) {
      get_evento_detail(pk).then(setEvento);
      get_sessione({ evento: pk }).then(setSessioni);
    } else {
      get_sessione_detail(pk).then(ses => {
        setSessioni([ses])
        get_evento_detail(ses.evento).then(setEvento);
      })

    }

    const risposte_func = isEvento(location) ?
      () => get_risposta({ evento: pk })
      : () => get_risposta({ sessione: pk })
    const jolly_func = isEvento(location) ?
      () => get_jolly({ evento: pk }) :
      () => get_jolly({ sessione: pk });
    const squadre_func = isEvento(location) ?
      () => get_squadra({ sessione__evento: pk }) :
      () => get_squadra({ sessione: pk })
    risposte_func().then(setConsegnate);
    jolly_func().then(setJolly)
    squadre_func().then(setSquadre)
  }, [location])


  useEffect(() => {
    if (!evento) return
    const timeout = refreshProblemi(evento);
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [evento])

  const reset_timeout = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      if (!!reset_timeout.current) {
        clearTimeout(reset_timeout.current)
      }
    }
  }, [])


  if (!login.logged_in) {
    login_url();
  }

  const setSorting = (newkind: OrdineKind) => {
    if (ordineKind == newkind) setOrdineDir(ordineDir * -1)
    else setOrdineKind(newkind)
  }

  const initsquad = squadre.length == 1 ? squadre[0].id : 0;
  const initialValues: FormValues = {
    squadra: initsquad as number, problema: 0, valore: 0, valore_exp: 0, isSecondButton: false,
    momento_consegna: (new Date()).toISOString(),
    auto_time: false,
  }
  const squadreMap = squadre.reduce((dict, sq) => {
    dict[sq.id.toString()] = sq.nome;
    return dict;
  }, {} as { [pk: string]: string })


  if (!evento) return <LoadingCircle />

  return (
    <div className="container">
      <h1 className="page-header">Consegna una risposta</h1>
      <DisplayOrario evento={evento} />
      <Formik
        onSubmit={(values, actions) => {
          let func: () => ReturnType<typeof post_jolly> | ReturnType<typeof post_risposta>;
          if (values.isSecondButton) {
            func = () => post_jolly({
              problema: values.problema,
              squadra: values.squadra,
            })
          } else {
            const extra = values.auto_time ? {} : { momento_consegna: values.momento_consegna }
            func = () => post_risposta({
              problema: values.problema,
              squadra: values.squadra,
              valore: values.valore.toString(),
              valore_exp: values.valore_exp,
              ...extra,
            })
          }
          func().then(ans => {
            let success_message;
            if (values.isSecondButton) {
              success_message = "Jolly impostato correttamente.";
              setJolly([ans, ...jolly])
            } else {
              success_message = `Risposta correttamente consegnata` +
                ` ${nice_date_discorsiva(ans.momento_consegna)}.`
              if ((ans as Risposta).corretto) {
                success_message = "Corretto! " + success_message;
              } else {
                success_message = "Errato! " + success_message;
              }
              setConsegnate([ans as Risposta, ...consegnate])
            }
            setErrore(null);
            setSuccess(success_message);
            const initsquad = squadre.length == 1 ? squadre[0].id : 0;
            const initialValues = {
              squadra: initsquad as number, problema: 0, valore: 0, valore_exp: 0, isSecondButton: false,
              momento_consegna: (new Date()).toISOString(), auto_time: values.auto_time,
            }
            actions.resetForm({ values: initialValues });
          }).catch(e => handle_drf_form_error(e, actions, s => { setErrore(s); setSuccess(null) }))
            .finally(() => {
              actions.setSubmitting(false);
              if (reset_timeout.current !== null) {
                clearTimeout(reset_timeout.current);
              }
              reset_timeout.current = window.setTimeout(
                () => { setErrore(null); setSuccess(null) }, 15000
              )
            });

        }}
        initialValues={initialValues}
      >
        {({ values, isSubmitting, setFieldValue }) => {
          let udm_swag;
          if (values.problema) {
            let udm = problemi.find((item) => (item.id == values.problema))?.udm_soluzione;
            udm_swag = udm_swag_(!!udm ? udm : "");
          }
          return (
            <>
              <Form autoComplete="off" className="needs-validation">
                <FormGroupBootstrap
                  type="select" name="squadra" choices={squadre}
                  displayChoice={(squadra: Squadra) => displaySquadra(squadra, sessioni, !!evento ? [evento] : [])}
                />
                <FormGroupBootstrap
                  type="select" name="problema" choices={problemi}
                  displayChoice={(item: Squadra) => item.nome}
                  help_text={"La lista dei problemi è disponibile " +
                    "solo dopo l'inizio dell'evento. Viene ricaricata automaticamente."}
                />
                <div className="row">
                  <div className="col">
                    <FormGroupBootstrap
                      type="number" name="valore" step={DECIMAL_PLACES}
                    />
                  </div>
                  <span className="mt-3">✕10</span>
                  <div className="col-sm-3 ml-0">
                    <Field
                      component={CustomInputForm}
                      className="form-control"
                      name="valore_exp"
                      type="number" step={1}
                    />
                  </div>
                  {udm_swag}
                </div>
                {canCancellareRisposte(login, evento) &&
                  <>
                    <FormGroupBootstrap
                      type="checkbox" name="auto_time"
                      help_text="Seleziona questo checkbox per selezionare automaticamente il tempo corrente per la consegna della risposta"
                    />
                    {!values.auto_time &&
                      <>
                        <FormGroupBootstrap
                          type="datetime" name="momento_consegna"
                          help_text="Seleziona manualmente l'orario per inviare una risposta ad un tempo diverso da quello attuale. Seleziona 'Auto time' per evitare di dover selezionare manualmente il tempo ogni volta."
                          timePrecision="seconds"
                        />

                        <div className="d-flex justify-content-end">
                          <button onClick={() => setFieldValue("momento_consegna", (new Date()).toISOString())}
                            className="btn btn-pomegranate"
                            type="button"
                          >
                            Sincronizza orario
                          </button>
                        </div>
                      </>
                    }
                  </>
                }
                <div className="d-flex justify-content-between bd-highlight">
                  <button
                    type="reset"
                    className="btn btn-warning">Reset
                  </button>

                  <button
                    disabled={isSubmitting}
                    className="btn btn-primary"
                    onClick={() => setFieldValue('isSecondButton', false)}
                    type="submit"
                  >
                    Invia
                  </button>

                </div>
                <div className="d-flex justify-content-center mb-3">
                  <button
                    onClick={() => setFieldValue('isSecondButton', true)}
                    className="btn btn-secondary"
                    type="submit"
                  >
                    Imposta come jolly
                  </button>

                </div>
              </Form>
              <ErrorBlockGeneric error={errore} />
            </>
          );
        }}
      </Formik>
      {success &&
        <div className="alert alert-success" role="alert">
          {success}
        </div>
      }
      <div>
        <h3>Risposte consegnate</h3>
        <button
          className="btn btn-warning my-1"
          title="Rende visibili tutte le risposte/le nasconde"
          type="button"
          onClick={() => setSpoilerRisposte(val => !val)}
        >
          Toggle spoiler
        </button>
        <div className="table-responsive">
          <table className="table table-bordered table-hover">
            <thead className="thead-dark">
              <tr>
                <th>Sessione</th>
                <th onClick={() => setSorting("p")}><i className="fa fa-sort" /> Problema</th>
                <th onClick={() => setSorting("s")}><i className="fa fa-sort" /> Squadra</th>
                <th onClick={() => setSorting("t")}><i className="fa fa-sort" /> Timestamp</th>
                <th>Valore</th>
                {canCancellareRisposte(login, evento) && <th>Elimina</th>}
              </tr>
            </thead>
            <tbody>
              {[...consegnate.filter(filtraFactory(location, squadre, evento, sessione))].sort((a, b) => {
                if (ordineKind == "t") {
                  return ordineDir * ((new Date(a.momento_consegna)).getTime() - (new Date(b.momento_consegna)).getTime())
                } else if (ordineKind == "p") {
                  return ordineDir * (a.problema - b.problema)
                } else {
                  const asq = squadreMap[a.squadra.toString()];
                  const bsq = squadreMap[b.squadra.toString()];
                  if (!asq || !bsq) return 0;
                  const mult = (asq < bsq) ? 1 : -1;
                  return ordineDir * mult;
                }
              }).map(item => {
                const color_class = item.corretto ? "table-success" : "table-danger";
                const udm = problemi.find(prob => prob.id == item.problema)?.udm_soluzione;
                const testo_valore = `$\\SI{${item.valore}e${item.valore_exp}}{}$ ${udm}`;
                const rendered = <MdMath source={testo_valore} />
                const valore = spoilerRisposte ?
                  rendered :
                  <Spoiler>{rendered}</Spoiler>;
                const sq = squadre.find(i => i.id == item.squadra)
                const prob = problemi.find(i => i.id == item.problema);
                if (!sq || !prob) {
                  return <tr key={item.id}><td><LoadingCircle /></td></tr>
                }
                const sess = isEvento(location) ?
                  sessioni.find(i => i.id == sq.sessione) :
                  sessione;
                if (!sess) {
                  return <tr key={item.id}><td><LoadingCircle /></td></tr>
                }
                return (
                  <tr key={item.id} className={color_class}>
                    <td>{sess.nome}</td>
                    <td>{prob.nome}</td>
                    <td>{sq.nome}</td>
                    <td>{nice_date(item.momento_consegna)}</td>
                    <td>{valore}</td>
                    {canCancellareRisposte(login, evento) &&
                      <ConfirmedButton
                        type="danger"
                        onSubmit={() => {
                          delete_risposta(item.id)
                            .then(() => setConsegnate(consegnate.filter(ans => ans.id != item.id)))
                            .catch(() => {
                              store.dispatch(add_toast_action({
                                title: "Errore di cancellazione",
                                message: `La cancellazione della risposta ${item.id} non ` +
                                  "è andata a buon fine."
                              }))
                            })
                        }}
                      >
                        Cancella
                      </ConfirmedButton>
                    }
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <h3>Jolly impostati</h3>
        <button
          className="btn btn-warning my-1"
          title="Rende visibili tutti i jolly/li nasconde"
          type="button"
          onClick={() => setSpoilerJolly(val => !val)}
        >
          Toggle spoiler
        </button>
        <div className="table-responsive">
          <table className="table table-bordered table-hover">
            <thead className="thead-dark">
              <tr>
                <th>Sessione</th>
                <th>Squadra</th>
                <th>Jolly</th>
              </tr>
            </thead>
            <tbody>
              {jolly.filter(filtraFactory(location, squadre, evento, sessione)).map(item => {
                const prob = problemi.find(i => i.id == item.problema);
                const sq = squadre.find(i => i.id == item.squadra);
                if (!sq || !prob) {
                  return <tr key={item.id}><td><LoadingCircle /></td></tr>
                }
                const sess = isEvento(location) ?
                  sessioni.find(i => i.id == sq.sessione) :
                  sessione;
                if (!sess) {
                  return <tr key={item.id}><td><LoadingCircle /></td></tr>
                }
                const val = spoilerJolly ?
                  prob.nome :
                  <Spoiler>{prob.nome}</Spoiler>
                return (
                  <tr className="table-yellow" key={item.id}>
                    <td>{sess.nome}</td>
                    <td>{sq.nome}</td>
                    <td>{val}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ConsegnaRisposta;
