import { config, Config } from './config';
declare var self: ServiceWorkerGlobalScope;
export {};


interface ExtendableEvent extends Event {
  waitUntil(fn: Promise<any>): void;
}

interface ActivateEvent extends ExtendableEvent {

}

const clearCacheIfDifferent = async (event: ActivateEvent, opts: Config) => {
  const cacheKeys = await caches.keys();

  const oldCacheKeys = cacheKeys.filter(key => key.indexOf(opts.version) !== 0);
  const deletePromises = oldCacheKeys.map(oldKey => caches.delete(oldKey));
  return Promise.all(deletePromises);
}

export const activate_listener = (event: ActivateEvent) => {
  event.waitUntil(clearCacheIfDifferent(event, config).then(() => self.clients.claim()))
}
