import React, { useState, useEffect } from 'react'
import { Formik, Form, FormikHelpers as FormikActions } from 'formik';
import { useParams, useNavigate } from 'react-router-dom'

import { FormGroupBootstrap, ErrorBlockGeneric, ConfirmedButton } from '../common-components'
import { TIPI_EVENTO, DEFAULT_PARAMS_STANDARD_MODEL } from '../globals'
import { handle_drf_form_error } from 'django-rest-react'
import { patch_evento, post_evento, get_evento_by_pk } from '../api'
import { InvitaRuolo, MultipleSoluzioniForm } from '../components'
import { get_problema } from '../api';
import { Evento } from '../api/types'
import { patch_file_pubblicazione_programmata_creation, post_file_pubblicazione_programmata_creation } from '../api/auto-apis';
import { ProblemaCreationForm } from '../api/auto-types';


const pageMatch = useParams<{ pk: string }>;
type FormValues = Parameters<typeof post_evento>[0];

const guess_other_dates = (inizio: Date) => {
  const fine = new Date(inizio);
  fine.setMinutes(inizio.getMinutes() + 90);
  const fine_consegna_jolly = new Date(inizio);
  fine_consegna_jolly.setMinutes(inizio.getMinutes() + 15);
  const fine_iscrizioni = new Date(inizio);
  fine_iscrizioni.setMinutes(fine_iscrizioni.getMinutes() - 5);

  return {
    inizio: inizio.toISOString(),
    fine: fine.toISOString(),
    fine_consegna_jolly: fine.toISOString(),
    fine_iscrizioni: fine_iscrizioni.toISOString(),
  }
}

const round_start = () => {
  const now = new Date();
  now.setHours(now.getHours() + 1);
  now.setMinutes(0, 0, 0);
  return now
}


const Spiegone = () => {
  return (
    <div>
      <h3>Funzionamento degli eventi non OliFis</h3>
      <p>Ogni docente dotato di account su questo sito, la cui scuola risulti correttamente iscritta alla Gara a Squadre di Fisica, può utilizzare questo sito per organizzare degli eventi pubblici o privati. La piattaforma permette di inserire le risposte corrette ai vari problemi, far iscrivere le squadre e consegnare le risposte.</p>
      <h4>Visibilità degli eventi</h4>
      <p>Nel form sottostante potrete scegliere la visibilità del vostro evento, scegliendo fra</p>
      <ul>
        <li>Evento privato non OliFis</li>
        <li>Evento pubblico non OliFis</li>
        <li>Evento ufficiale OliFis</li>
      </ul>
      <p>
        La voce "Evento ufficiale OliFis" serve solo a ricordare che potete contattare per email il comitato organizzatore per organizzare un allenamento scolastico, ma la piattaforma vi impedirà di creare un evento ufficiale senza l'approvazione di un amminstratore.
      </p>

      <h4>Terminologia</h4>
      <p>Per ogni evento vengono designati degli <i>amministratori</i> e degli <i>inseritori</i>. Ogni amministratore ha completi diritti sull'evento, ne può modificare data, titolo e i vari parametri. Essere inseritori invece permette di consegnare ed eliminare risposte <b>non solo per le proprie squadre, ma per tutte le squadre</b>. Un amministratore ha anche tutti i privilegi di un inseritore, ma non è vero il viceversa.</p>
      <p><b>Importante:</b> gli inseritori non sono gli unici utenti in grado di consegnare risposte. Negli eventi pubblici non OliFis, se un utente normale iscrive una squadra all'evento, può consegnare risposte per la sua squadra senza dover chiederlo all'inseritore/amministratore. Gli inseritori sono tuttavia gli unici a poter consegnare risposte per squadre non proprie.</p>

      <p>Quando un evento viene creato da un utente, questo utente diventa automaticamente amministratore dell'evento e può a sua volta nominare altri amministratori e inseritori. Un evento può funzionare regolarmente anche avendo solo un amministratore, la possibilità di invitare altri è una funzionalità offerta ma non indispensabile.</p>

      <h5>Evento privato non OliFis</h5>
      <p>Un evento privato non OliFis è visibile solo ai suoi amministratori e inseritori.</p>
      <p>Questa funzionalità è pensata principalmente per permettere ai docenti di organizzare allenamenti interni al proprio istituto.</p>

      <h5>Evento pubblico non OliFis</h5>
      <p>L'evento pubblico è visibile da tutti su questo sito. Ogni docente dotato di account può iscrivere una o più squadre a questo evento, entro il termine specificato nel form qui sotto.</p>

      <p>Ci sono due differenze fra questo tipo di eventi e gli eventi ufficiali OliFis</p>
      <ul>
        <li>Questi eventi vengono organizzati in modo completamente indipendente dal comitato centrale, la cui approvazione non è richiesta.</li>
        <li>Per lo stesso motivo, i testi proposti non hanno subito la procedura di review del comitato centrale e sono completamente a carico dei docenti organizzatori.</li>
      </ul>

      <h5><b>Importante</b></h5>
      <p>Per gli eventi ufficiali OliFis acquistiamo un hosting estremamente performante per evitare spiacevoli crash e problemi tecnici. Questo hosting è chiaramente molto costoso, e per questo viene preso solo per il periodo necessario, mentre per il resto del tempo si utilizza un hosting buono ma meno performante. Se è vostra intenzione organizzare un evento con più di 50 persone collegate a vedere la classifica, è una buona idea contattarci in modo da avere hosting adeguato.</p>



      <br />
      <br />
      Per qualsiasi domanda, dubbio o suggerimento sul funzionamento del sito, si contatti <a href="mailto:gas@olifis.it">gas@olifis.it</a>.
      <hr />
    </div>
  )
}



interface IProps {
  tipologia: "problemi" | "soluzioni",
  evento: number,
  old?: number
}
const CaricaTestoOSoluzioni = (props: IProps) => {
  const [error, setError] = useState<string | null>(null);

  return (
    <Formik
      onSubmit={(values, actions) => {
        const func = !props.old ?
          post_file_pubblicazione_programmata_creation
          : (val: Parameters<typeof patch_file_pubblicazione_programmata_creation>[1]) => patch_file_pubblicazione_programmata_creation(props.old as number, val)

        func({
          il_file: values.il_file as unknown as File,
          evento: props.evento,
          tipologia: props.tipologia,
        })
          .then(() => window.location.reload())
          .catch(e => handle_drf_form_error(e, actions, setError))
          .finally(() => actions.setSubmitting(false))
      }}
      initialValues={{ il_file: undefined }}
      enableReinitialize
    >
      {({ isSubmitting }) => {


        return (
          <>
            {props.old && <p><b>Importante: </b>Il file è già stato caricato, ma puoi modificarlo usando questo form.</p>}
            <Form className="needs-validation">
              <FormGroupBootstrap type="file" name="il_file" displayName="File in PDF" />
              <div className="d-flex justify-content-between bd-highlight mb-3">
                <button
                  type="reset"
                  className="btn btn-warning">Reset
                </button>
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="btn btn-primary"
                >
                  Invia
                </button>
              </div>
              <ErrorBlockGeneric error={error} />
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

type EventoDoppio = Parameters<typeof post_evento>[0] | Awaited<ReturnType<typeof get_evento_by_pk>>


const ModificaEvento = (props: {}) => {
  const inizio_iscrizioni = new Date();
  const inizio_tondo = round_start();
  const [error, setError] = useState<string | null>(null);
  const params = pageMatch();
  const navigate = useNavigate();
  const [showSm, setShowSm] = useState(false);

  const [old, setOld] = useState<EventoDoppio>({
    ...DEFAULT_PARAMS_STANDARD_MODEL,
    titolo: "",
    tipologia: "priv" as Evento["tipologia"],
    ...guess_other_dates(inizio_tondo),
    inizio_iscrizioni: inizio_iscrizioni.toISOString(),
    problemi: [] as ProblemaCreationForm[],
    sessioni: [],
  });

  const modifica = !!params.pk
  const pk = modifica ? parseInt(params.pk) : null;

  if (modifica) {
    useEffect(() => {
      if (!pk) return;
      get_evento_by_pk(pk).then(ev => {
        // Qui bisogna pescare i problemi. Non le sessioni, che non sono toccabili
        get_problema({ evento: pk }).then(probs_ => {
          const probs = probs_ as unknown as ProblemaCreationForm[]
          setOld({
            ...ev,
            problemi: probs,
          })
        })
      })
    }, [pk])
  }

  const titolo = modifica ? "Modifica evento" : "Creazione evento";
  const initialValues = old;
  // ATTENZIONE! Non cambiare questa riga, guarda i commenti sotto.
  const oldevent = modifica ? old as unknown as Evento : null;

  return (
    <div className="container">
      <h1 className="page-header">{titolo}</h1>
      <Spiegone />

      { // Attenzionati, qui usiamo oldevent invece di modifica, perché altrimenti typescript scoppia.
        // La cosa funziona ugualmente perché se si guarda la definzione di oldevent è null se modifica è false.
        // ATTENZIONE! non è l'unico posto dove viene fatta questa cosa, ce n'è un'altra sotto
        !!oldevent &&
        <div>
          <h4>{"Amministratori dell'evento"}</h4>
          <ul>
            {oldevent.amministratori_str?.map(val => <li key={val}>{val}</li>)}
          </ul>
          <InvitaRuolo evento={oldevent.id} ruolo="adm" />
          <h4>{"Inseritori dell'evento"}</h4>
          <ul>
            {oldevent.inseritori_str?.map(val => <li key={val}>{val}</li>)}
          </ul>
          <InvitaRuolo evento={oldevent.id} ruolo="ins" />
        </div>
      }

      <hr />

      <Formik onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => {
        const subfunc = modifica ? (v: FormValues) => patch_evento(pk as number, v) : post_evento;
        subfunc(values)
          .then(ev => navigate(`/evento/${ev.id}/`))
          .catch(err => handle_drf_form_error(err, actions, setError))
          .finally(() => actions.setSubmitting(false))
      }}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ isSubmitting, handleSubmit }) => {
          return (
            <Form className="needs-validation">
              <FormGroupBootstrap type="text" name="titolo" />
              <FormGroupBootstrap type="select" name="tipologia" choices={TIPI_EVENTO} />
              <FormGroupBootstrap type="datetime" name="inizio" displayName="Inizio evento" />
              <FormGroupBootstrap type="datetime" name="fine" displayName="Fine evento" />
              <FormGroupBootstrap type="datetime" name="fine_consegna_jolly" displayName="Scadenza consegna jolly" />
              <FormGroupBootstrap type="datetime" name="inizio_iscrizioni" />
              <FormGroupBootstrap type="datetime" name="fine_iscrizioni" help_text={"Deve essere prima dell'inizio dell'evento"} />

              {!showSm && <button className="btn btn-danger" type="button" onClick={() => setShowSm(true)}>Mostra parametri liberi del sistema di punteggi</button>}
              {showSm &&
                <>
                  <button className="btn btn-warning" type="button" onClick={() => setShowSm(false)}>Nascondi parametri liberi</button>
                  <FormGroupBootstrap
                    type="number" step={1}
                    name="PTI_TOLTI_ERRORE" displayName="Punti tolti errore"
                    help_text="Parametro 'E' sul regolamento."
                  />
                  <FormGroupBootstrap
                    type="number" step={1}
                    name="PTI_BASE_MONTEPREMI" displayName="Punteggio difficoltà massimo"
                    help_text="Parametro indicato sul regolamento"
                  />
                  <FormGroupBootstrap
                    type="number" step={1} name="MAX_ERRORI_RAISE_MONTEPREMI"
                    displayName="Cap risposte sbagliate"
                    help_text="Parametro 'h' sul regolamento"
                  />
                  <FormGroupBootstrap
                    type="number" step={1} name="ERRORE_PUNTEGGI_RAISE"
                    displayName="Aumento punteggio per errore"
                    help_text="Parametro 'A' sul regolamento"
                  />
                  <FormGroupBootstrap
                    type="number" step={1} name="BONUS_VELOCITA"
                    displayName="Bonus velocità"
                  />
                </>
              }
              <MultipleSoluzioniForm />
              <div className="d-flex justify-content-between bd-highlight mb-3">
                <button
                  type="reset"
                  className="btn btn-warning">Reset
                </button>
                <ConfirmedButton
                  disabled={isSubmitting}
                  type="primary"
                  onSubmit={handleSubmit}
                >
                  {modifica && "Salva modifiche"}
                  {!modifica && "Crea evento"}
                </ConfirmedButton>
              </div>
            </Form>
          )
        }}
      </Formik>
      <ErrorBlockGeneric error={error} />
      // Attenzione! come sopra, oldevent e modifica sono collegati.
      {!!oldevent &&
        <>
          <h4>Testo e soluzioni</h4>
          <p>In questa sezione puoi caricare testo e soluzioni della prova. Il testo verrà reso pubblico all'inizio dell'evento, le soluzioni alla fine.</p>
          <h5>Testo</h5>
          <CaricaTestoOSoluzioni tipologia="problemi" evento={oldevent.id} old={oldevent.file_problemi?.id} />
          <h5>Soluzioni</h5>
          <CaricaTestoOSoluzioni tipologia="soluzioni" evento={oldevent.id} old={oldevent.file_soluzioni?.id} />
        </>
      }
    </div>
  )
}


export default ModificaEvento
