import React, { useState } from 'react';
import { Formik, Form, FormikHelpers as FormikActions } from 'formik';

import { handle_drf_form_error, sleep } from "django-rest-react";
import { FormGroupBootstrap, ErrorBlockGeneric } from '../common-components'
import { displaySessione } from '../globals'
import { Sessione, JsonTimestamp } from '../api/types'
import { delete_session_cache } from '../api'
import { useSessione, useEvento } from '../reducers'


type FormValues = Parameters<typeof delete_session_cache>[1];


const DeleteSessionCache = (props: {}) => {

  const [error, setError] = useState(null as string | null);
  const [success, setSuccess] = useState(null as string | null);

  const sessioni = useSessione(props);
  const eventi = useEvento(props);
  const initial_values: Partial<JsonTimestamp> = {
    ora: undefined,
    sessione: undefined,
    json: { a: "a" },
  };


  return (
    <div>
      <h1 className="page-header">
        Cancella cache per sessione
      </h1>
      <ErrorBlockGeneric error={error} />
      {success && <div className="alert alert-success" role="alert">Cache resettata</div>}
      <Formik
        onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => {
          if (!values.sessione) {
            actions.setErrors({ "sessione": "Seleziona una sessione" });
            actions.setSubmitting(false);
            return
          }
          delete_session_cache(values.sessione, values)
            .then(() => setSuccess("ok"))
            .catch(e => handle_drf_form_error(e, actions, setError))
            .finally(() => {
              actions.setSubmitting(false);
              sleep(10000).then(() => setError(null));
            })
        }}
        initialValues={initial_values}
      >
        {({ isSubmitting }) => {
          return (
            <Form className="form col-md needs-validation">
              <FormGroupBootstrap
                type="select" name="sessione" choices={sessioni} search
                displayChoice={(ses: Sessione) => displaySessione(ses, eventi)}
              />
              <FormGroupBootstrap
                type="datetime" name="ora" timePrecision="seconds"
              />
              <div className="d-flex justify-content-between bd-highlight mb-3">
                <button
                  disabled={isSubmitting}
                  className="mr-auto btn btn-primary"
                  type="submit"
                >
                  Invia
                </button>
                <button
                  type="reset"
                  className="ml-auto btn btn-warning">Reset
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div >
  )
}

export default DeleteSessionCache;
