import { config } from './config';
// import { push_listener, notification_click } from './push';
import { fetch_listener } from './cache';
import { activate_listener } from './activation'
import { message_listener } from './message'


declare var self: ServiceWorkerGlobalScope;
export {};

// self.addEventListener('install', event => {
//   event.waitUntil(
//     caches.open(cacheName('static', config)).then(cache => cache.addAll(config.precachingItems))
//       .then(() => self.skipWaiting())
//   );
// });

self.addEventListener('install', event => {
  self.skipWaiting();
});

self.addEventListener('activate', activate_listener);
self.addEventListener('fetch', fetch_listener);
// self.addEventListener('push', push_listener);
// self.addEventListener('notificationclick', notification_click);
self.addEventListener('message', message_listener);
